import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { checkoutCredits } from '../reducers/userReducer';

import environment from '../helpers/envProvider';
import { ReactComponent as CloseIcon } from '../assets/CloseIcon.svg';
import { ReactComponent as SparkleIcon } from '../assets/SparkleGreen.svg';
import { ReactComponent as CreditIcon } from '../assets/CreditIcon.svg';
import ProfileImage from '../assets/ProfileImage.png';
import SumolingPlans from '../data/sumoling-plans';
import plans from '../data/plans';
import SpinnerLoader from './base/SpinnerLoader';
import authAxiosInstance from '../helpers/auth-axios';

const PAYMENT_ENVIRONMENT =
  environment('REACT_APP_PAYMENT_ENVIRONMENT') || 'production';

const CreditCheckoutPriceId =
  PAYMENT_ENVIRONMENT === 'test'
    ? 'price_1OLC3IHPReHZNX9KDhNzILnV'
    : 'price_1OLC25HPReHZNX9KMRJsrBH8';

const GetMoreCreditsFormV4 = function (props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const sumolingLicense = useSelector(
    (state) => state.userSlice.sumolingLicense
  );

	const [packages, setPackages] = useState([])
	const [loading, setLoading] = useState(false)

	const loadPackages = async () => {
		setLoading(true)
		try {
      const response = await authAxiosInstance.get(
        `/api/prices`,
				{
					params: {
						filters: `{"group":"extra-credit"}`,
					},
				}
      );

			setPackages(response.data)
		} catch(e) {
			console.log(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		loadPackages()
	}, [])

  const [selectedPackageId, setSelectedPackageId] = useState(null);
	const selectedPackage = useMemo(() => {
		return packages.find(p => p._id === selectedPackageId)
	}, [packages, selectedPackageId,])

  const getProfileImage = () => {
    if (user?.googlePhoto) return user?.googlePhoto;
    if (user?.discordId && user?.discordAvatar)
      return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.discordAvatar}.png`;
    return ProfileImage;
  };

  const getUsername = () => {
    if (user?.name) return user.name;
    if (user?.googleNames?.length > 0) return user.googleNames[0];
    if (user?.discordUsername)
      return `${user.discordUsername}#${user.discordDiscriminator}`;
  };

  const currentPlan = useMemo(() => {
    if (user?.isSumoling) {
      return SumolingPlans.find(
        (val) =>
          user?.appsumoLicense?.productId === val.plan_id ||
          sumolingLicense?.productId === val.plan_id
      );
    }
    if (!user?.plan) {
      return plans[0];
    }
    return plans.find(
      (val) =>
        user?.plan?.productId === val.monthlyPriceId ||
        user?.plan?.productId === val.yearlyPriceId
    );
  }, [user, sumolingLicense]);

  const getCredits = async () => {
    try {
      // const response = await dispatch(checkoutCredits({ price: 'price_1N3KQwHZuEcshHGajO58Newa', quantity: credits[0]})).unwrap() // Produciton
      const response = await dispatch(
        checkoutCredits({
          price: selectedPackage?.priceId,
          quantity: 1,
        })
      ).unwrap(); // Testing

      window.open(response.callback, '_self');
    } catch (e) {
      toast(
        e.response?.data?.details || e.response?.data || 'An error occured.',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000,
        }
      );
    }
  };

  const handleSelectPackage = (creditPackage) => {
    setSelectedPackageId(creditPackage._id);
  };

  return (
    <div
      className='bg-black rounded-[8px] w-[670px] text-center'
      onClick={(e) => e.stopPropagation()}
    >
      <div className='flex items-center justify-between p-[20px]'>
        <div className='flex items-center gap-5'>
          <img
            src={getProfileImage()}
            referrerPolicy='no-referrer'
            className='rounded-full h-[60px] border-4 border-white'
            alt='user profile'
          />
          <div className='flex flex-col items-start'>
            <h1 className='text-white font-bold text-[24px] font-poppins'>
              {getUsername()}
            </h1>
            <div className='flex justify-center items-start text-gray-400 text-[14px] font-light font-poppins gap-2'>
              <span>My Credits:</span>
              <span className='mr-8'>
                {user?.remainingCredits}
              </span>
              <span> Extra Credits:</span>
              <span className=''>{user?.individualCredits}</span>
            </div>
          </div>
        </div>
        <button onClick={props.onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className='flex flex-col w-full py-4 px-[20px] bg-app-black items-start justify-start rounded-b-[8px]'>
        <div className='flex gap-1'>
          <SparkleIcon />
          <h1 className='text-[16px] text-white font-bold font-montserrat'>
            Buy Extra Credits
          </h1>
        </div>
        <div className='flex text-left'>
          <span className='text-white text-opacity-80 text-[12px] font-bold'>
            Extra credits never expire!
            <span className='ml-1 font-normal text-white text-opacity-60'>
              You’ll need an active subscription to use them, but don’t worry—if
              your subscription ends or you cancel, your credits stay safe.
              They’ll be ready and waiting for you as soon as you renew or
              resubscribe.
            </span>
          </span>
        </div>
        <div className='flex w-full items-center justify-center flex-wrap gap-[14px] py-[20px] relative min-h-[200px] my-4'>
					{loading ? (
						<SpinnerLoader timed={false} isLoading={loading} />
					) : (
						<>
							{packages.map((pack, index) => (
								<button
									key={pack._id}
									onClick={() => handleSelectPackage(pack)}
									className={`relative flex flex-col items-center justify-center w-[200px] h-[120px] bg-[#1D1E25] rounded-[12px] border-2 ${
										selectedPackage?._id === pack._id
											? 'border-app-green'
											: 'border-none'
									}`}
								>
									{pack.stripe_metadata?.offValue && <div className='absolute top-0 right-0 rounded-tr-[10px] py-1 px-2 bg-app-green text-[10px] font-inter font-extrabold'>
										{pack.stripe_metadata?.offValue}% off
									</div>}
									<div className='flex items-center justify-center'>
										<CreditIcon />
										<h1 className='text-[20px] text-app-green font-extrabold '>
											{pack.stripe_metadata?.credits?.toLocaleString()}
										</h1>
									</div>
									{pack.stripe_metadata.beforeOff && (
										<div className='flex items-center justify-center bg-button-purple bg-opacity-20 w-[42px] h-[20px] rounded-full'>
											<div className='relative text-button-purple text-[12px]'>
												<span>{pack.stripe_metadata?.beforeOff}</span>
												<div className='absolute inset-0 flex items-center justify-center'>
													<div className='h-[1px] w-full bg-button-purple'></div>
												</div>
											</div>
										</div>
									)}
									<div className='text-[16px] font-inter font-medium text-white mt-1'>
										${pack.price}
									</div>
								</button>
							))}
						</>
					)}
        </div>
        <div className='flex items-center justify-end w-full gap-2'>
          <div className='flex items-center justify-center gap-2'>
            <span className='text-[14px] text-white text-opacity-90 font-inter font-light'>
              Credits:
            </span>
            <div className='flex gap-1 items-center justify-center'>
                <CreditIcon />
                <span className='text-app-green font-extrabold'>
                  {selectedPackage?.stripe_metadata?.credits || 0}
                </span>
            </div>
            <span className='text-[14px] text-white text-opacity-90 font-inter font-light'>
              Total:
            </span>
            <span className='text-white text-[14px] font-semibold'>
              ${selectedPackage ? selectedPackage?.price : 0}
            </span>
          </div>
          <div className='flex items-center justify-center gap-3'>
            <button
              className='bg-ld-purple p-1 px-4 font-inter font-bold text-white text-[16px] w-[105px] h-[46px] rounded-[12px]'
              onClick={getCredits}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetMoreCreditsFormV4;
