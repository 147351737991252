import React, { useEffect, useMemo, useState } from 'react';
import SuccessIcon from '../assets/icon_verify.png'
import FailureIcon from '../assets/verify_failed.png'
import ProtectedRoute from '../components/ProtectedRoute';
import AppLayout from '../layouts/AppLayout';
import { Helmet } from 'react-helmet';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Tap from '@tapfiliate/tapfiliate-js';
import { useSelector, useStore } from 'react-redux';
import authAxiosInstance from '../helpers/auth-axios';
import ReactGA from "react-ga4";


const VALUES = {
	success: {
		title: "Payment Successful",
		header: "Payment successfully processed!",
		description: "Dive into Logo Diffusion to experience the future of logo design",
		icon: SuccessIcon,
	},
	failure: {
		title: "Payment Failed",
		header: "Payment was not processed!", 
		description: "Your card has not been debited. However, if you notice any charges, an automatic refund will be processed, it typically processes within 2-5 days. If this doesn't occur, we recommend contacting your bank to inquire about this transaction.",
		icon: FailureIcon,
	},
}

var initiated = false;

const BaremetricsRedirect = (props) => {
	const store = useStore();

  const user = useSelector(state => state.userSlice.user);

	const load_script = async () => {
		if (initiated) return ;
		try {
			(function(){
				if(window.barepay&&window.barepay.created)
					window.console&&console.error&&console.error("Barepay snippet included twice.");
				else {
					window.barepay={created:!0};
					var a=document.createElement("script");
					a.src="https://baremetrics-dunning.baremetrics.com/js/application.js";a.async=!0;
					var b=document.getElementsByTagName("script")[0];
					b.parentNode.insertBefore(a,b);
					window.barepay.params = {
						access_token_id: "77ccf7f4-510c-4483-9388-dcaa063f6afb",
						customer_oid: store.getState().userSlice.user.stripeCustomerId
					}
					initiated = true
				}
			})();
		} catch(e) {
			console.log(e);
		}
	}

	useEffect(() => {
		if (user) {
			load_script();
		}
	}, [user]);


	return (
    <AppLayout>
			<ProtectedRoute>
				<Helmet>
					<title>Thanks for updating your details | Logo Diffusion</title>
				</Helmet>
				<div
					className="pb-[100px] xl:pb-[200px] w-full h-full flex flex-col items-center justify-center text-center"
				>

					<h1
						className="font-montserrat font-black text-[24px] xl:text-[42px] leading-[1.4em] mb-[15px] text-white"
					>
						Thank you for updating your details
					</h1>
					<barepay></barepay>

					<Link
						to="/"
						className="bg-ld-purple h-[50px] w-[159px] rounded-[10px] text-white gap-[11px] flex items-center justify-center font-poppins font-bold text-[14px] mb-[35px]"
					>
						Back to app
					</Link>
				</div>
			</ProtectedRoute>
		</AppLayout>
	);
};

export default BaremetricsRedirect;
