import AdminLayout from "../layouts/AdminLayout";
import { Admin, Resource, CustomRoutes, Layout, ListGuesser, EditGuesser } from "react-admin";
import { Route } from 'react-router-dom';
import dataProvider from "../dataProvider";

import AdminWhitelist from './AdminWhitelistCreator';
import AdminInvites from "./AdminInvites";
import AdminSendgridWhitelist from './AdminSendgridWhitelist';

import AdminQuestionnaireList from '../components/AdminQuestionnaireList';
import AdminQuestionnaireEdit from '../components/AdminQuestionnaireEdit';

import UserList from '../components/UserList';
import UserCreate from '../components/UserCreateForm';
import UserEdit from "../components/UserEditForm";
import UserRoleList from "../components/UserRoleList";
import WhitelistViewer from "../components/AdminWhitelistViewer";
import AdminDashboardMenu from "../components/AdminDashboardMenu";

import GenerationList from "../components/AdminGenerationList";
import AdminGenerationListNew from "../components/AdminGenerationListNew";

import RoadmapViewer from '../components/RoadmapViewer';
import RoadmapCreate from '../components/RoadmapCreate';
import RoadmapEdit from '../components/RoadmapEditForm';

import UserIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GalleryList from "../components/admin/GalleryList";
import GalleryEdit from "../components/admin/GalleryEdit";
import StyleTransferGalleryList from "../components/admin/StyleGalleryList";
import StyleTransferGalleryEdit from "../components/admin/StyleTransferGalleryEdit";
import StyleTransferGalleryCreate from "../components/admin/StyleTransferGalleryCreate";
import BlockedDomainList from "../components/admin/BlockedDomains/List";
import BlockedDomainEdit from "../components/admin/BlockedDomains/Edit";
import BlockedDomainCreate from "../components/admin/BlockedDomains/Create";
import PriceCreate from "../components/admin/Prices/PriceCreate";
import PriceEdit from "../components/admin/Prices/PriceEdit";
import PriceList from "../components/admin/Prices/PriceList";

const LDAdminDashboardLayout = (props) => {
  return (
    <Layout {...props} menu={AdminDashboardMenu} />
  )
}

function LDAdmin(props) {
  return (
    <AdminLayout>
      <Admin layout={LDAdminDashboardLayout} basename="/admin" dataProvider={dataProvider}>
        <Resource
          name="users"
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
          icon={UserIcon}
          options={{ label: 'Users' }}
        />

        <Resource
          name="userRoles"
          list={UserRoleList}
          options={{ label: 'User Roles' }}
          icon={SupervisedUserCircleIcon}
        />

        <Resource
          name="prices"
          list={PriceList}
          edit={PriceEdit}
          create={PriceCreate}
          options={{ label: 'Prices' }}
          icon={AttachMoneyIcon}
        />

        <Resource
          name="whitelist"
          list={WhitelistViewer}
          options={{ label: 'Whitelist Viewer' }}
          icon={SupervisedUserCircleIcon}
        />

        <Resource
          name="roadmap"
          list={RoadmapViewer}
          create={RoadmapCreate}
          edit={RoadmapEdit}
          options={{ label: 'Users' }}
        />

        <Resource
          name="questionnaire"
          list={AdminQuestionnaireList}
          edit={AdminQuestionnaireEdit}
          options={{ label: 'Questionnaire' }}
        />

        <Resource
          name="generation"
          list={GenerationList}
          options={{ label: 'Generations' }}
        />

        <Resource
          name="gallery"
          list={GalleryList}
          edit={GalleryEdit}
          options={{ label: 'Gallery' }}
        />

        <Resource
          name="style-transfer-gallery"
          list={StyleTransferGalleryList}
          edit={StyleTransferGalleryEdit}
          create={StyleTransferGalleryCreate}
          options={{ label: 'Style Transfer Gallery' }}
        />

        <Resource
          name="blocked-domains"
          list={BlockedDomainList}
          edit={BlockedDomainEdit}
          create={BlockedDomainCreate}
          options={{ label: 'Blocked Domains!' }}
        />

        <CustomRoutes>
          <Route path="invites" element={<AdminInvites />}/>
          <Route path="generationnew" element={<AdminGenerationListNew />}/>
          <Route path="whitelist-creator" element={<AdminWhitelist />}/>
          <Route path="sendgrid-whitelist" element={<AdminSendgridWhitelist />} />
        </CustomRoutes>
      </Admin>
    </AdminLayout>
  )
}

export default LDAdmin;
