import { BooleanField, BulkDeleteButton, Button, Datagrid, FilterList, FilterListItem, ImageField, List, ReferenceField, TextField, useListContext, useNotify } from 'react-admin';
import authAxiosInstance from '../../../helpers/auth-axios';


export const PriceList = () => (
    <List >
        <Datagrid
					rowClick="edit"
				>
            <TextField source="name" />

            <TextField source="priceId" />
            <TextField source="productId" />

            <TextField source="group" />
            <TextField source="type" />

            <TextField source="period" />

            <TextField source="order" />
        </Datagrid>
    </List>
);

export default PriceList;
