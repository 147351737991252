import React, { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const SpinnerLoader = (props) => {
  const { icon, isLoading, classNames, timed=true } = props;

  const [time, setTime] = useState(0);

  const timerRef = useRef();

  useEffect(() => {

    if (isLoading && timed) {
      setTime(0);
      const interval = setInterval(() => {
        setTime((t) => t + 1);
      }, 1000)

      timerRef.current = interval
    }

    return () => {
      if (timerRef.current) clearInterval(timerRef.current)
    }

  }, [isLoading, timed])

  if (!isLoading) return <></>

  return (
    <div className={twMerge(`z-10 absolute inset-0 bg-black bg-opacity-60 rounded-2xl flex items-center justify-center`, classNames?.backdrop)}>
      <div className="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-spinner-blue via-spinner-cyan to-spinner-lime ">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(100%-0.5rem)] h-[calc(100%-0.5rem)] bg-overlay-bg rounded-full border-2 border-overlay-border flex items-center justify-center">
        </div>
      </div>
      <div className='w-24 pt-1 aspect-square absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-1 items-center justify-center '>
        {icon}

				{timed && <span className="text-spinner-timer-gray text-sm">{time}s</span>}
      </div>
    </div>
  )
}

export default SpinnerLoader
