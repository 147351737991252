import { DateInput, Create, SimpleForm, ReferenceField, TextInput, Image, ImageField, BooleanInput, SelectField, AutocompleteArrayInput, useCreateSuggestionContext, Button, NumberInput, SelectInput } from 'react-admin';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';

const options = []
export const PriceCreate = () => (
    <Create>
        <SimpleForm>
          <Grid container spacing={8}>
            <Grid item xs={6}>
							<TextInput source="name" aria-readonly="false" fullWidth/>
            </Grid>
            <Grid item xs={6}>
							<TextInput source="priceId" aria-readonly="false" fullWidth/>
            </Grid>
            <Grid item xs={6}>
							<TextInput source="productId" aria-readonly="false" fullWidth/>
            </Grid>
            <Grid item xs={6}>
							<NumberInput source="order" fullWidth />
            </Grid>

            <Grid item xs={6}>
							<BooleanInput source="active" fullWidth />
            </Grid>

            <Grid item xs={6}>
							<SelectInput source="environment" choices={[
								{ id: 'test', name: 'Staging/Text'},
								{ id: 'production', name: 'Production'},
							]} fullWidth/>
            </Grid>

            <Grid item xs={6}>
							<SelectInput source="group" choices={[
								{ id: 'extra-credit', name: 'Extra Credit'},
								{ id: 'subscription', name: 'Subscription'},
							]} fullWidth/>
            </Grid>

            <Grid item xs={6}>
							<SelectInput source="type" choices={[
								{ id: 'onetime', name: 'One Time Payment'},
								{ id: 'subscription', name: 'Subscription'},
							]} fullWidth/>
            </Grid>

            <Grid item xs={6}>
							<SelectInput source="period" choices={[
								{ id: 'monthly', name: 'Monthly'},
								{ id: 'yearly', name: 'Yearly'},
							]} fullWidth/>
            </Grid>

            <Grid item xs={6}>
						{/* <TextField multiline source="metadata" /> */}
							<AutocompleteArrayInput source="metadata.perks" onCreate={(v) => {
								const option = { id: v, name: v }
								options.push(option)
								return option
							}} choices={options} fullWidth/>
            </Grid>


          </Grid>
        </SimpleForm>
    </Create>
);

export default PriceCreate;
