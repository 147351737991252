import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ProtectedRoute from '../../components/ProtectedRoute';
import AppLayout from '../../layouts/AppLayout';

import environment from '../../helpers/envProvider';
import Redesign from '../../components/redesign/Redesign';
import PromptInput from '../../components/input/PromptInput';
import DesktopContentStyleHelpers from '../../components/DesktopContentStyleHelpers';
import Generations from '../../components/generation/Generations';
import ImageViewer from '../../components/ImageViewer';
import { Tab } from '@headlessui/react';

import { ReactComponent as MessageIconBlack } from '../../assets/MessageIconBlack.svg';
import { ReactComponent as SettingsIconBlack } from '../../assets/SettingsIconBlack.svg';
import ImageEditor from '../../components/editor/ImageEditor';
import TutorialModal from '../../components/modals/TutorialModal';
import QuestionnaireModal from '../../components/modals/QuestionnaireModal';
import QueueStatus from '../../components/queue/QueueStatus';
import useGenerationEndpoint from '../../components/input/useGenerationEndpoint';
import V4GenerationHistory from '../../components/generation/V4GenerationHistory';
import GenerationsV4 from '../../components/generation/GenerationsV4';
import RedesignV4 from '../../components/redesign/RedesignV4';
import GenerationsV4Grid from '../../components/generation/GenerationsV4Grid';
// ${TempVar_API}
// import ImageFunctionServices from '../services/ImageFunctionService';

function toDataURL(src, callback, outputFormat) {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    const canvas = document.createElement('CANVAS');
    const ctx = canvas.getContext('2d');
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    const dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
    img.src = src;
  }
}

const TextPipeline = () => {
  const MOBILE_VIEWS = {
    TEXT_PROMPT: {
      name: 'Text prompt',
      icon: (
        <MessageIconBlack className='ui-not-selected:[&_path]:fill-white' />
      ),
    },
    PROMPT_SETTINGS: {
      name: 'Prompt Settings',
      icon: (
        <SettingsIconBlack className='ui-not-selected:[&_path]:fill-white' />
      ),
    },
  };

  const [selectedTab, setSelectedTab] = useState('TEXT_PROMPT');
  const [isViewChange, setIsViewChange] = useState(true);
  const generationRef = useRef();
  const { mode: version } = useGenerationEndpoint();

  const onViewChange = (isGridView) => {
    setIsViewChange(isGridView);
  };
  return (
    <AppLayout>
      <ProtectedRoute>
        <Helmet>
          <title>Generator | Logo Diffusion</title>
        </Helmet>
        <div
          className={`md:flex flex-col md:flex-row px-2 sm:px-4 md:px-4 flex ${
            version === 'v4.0' ? 'h-[100%]' : 'h-[90%]'
          }  overflow-y-auto md:overflow-y-hidden pt-[15px] 3xl:pt-[18px] 4xl:pt-[25px] pb-32 md:pb-4`}
        >
          <Tab.Group
            className='bg-app-search-gray p-1 grid grid-cols-2 rounded-lg md:hidden'
            selectedIndex={Object.keys(MOBILE_VIEWS).indexOf(selectedTab)}
            onChange={(ind) => setSelectedTab(Object.keys(MOBILE_VIEWS)[ind])}
          >
            <Tab.List>
              {Object.keys(MOBILE_VIEWS).map((viewKey) => (
                <Tab
                  key={`view-button-${MOBILE_VIEWS[viewKey].name}`}
                  className={
                    'ui-selected:bg-app-green ui-selected:text-black ui-not-selected:text-white flex items-center w-full justify-center text-xs gap-2 rounded-md py-1'
                  }
                >
                  {MOBILE_VIEWS[viewKey].icon}

                  <span className='font-semibold'>
                    {MOBILE_VIEWS[viewKey].name}
                  </span>
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
          {version === 'v4.0' ? (
            <div
              className='max-w-[321px] overflow-auto custom-scroll txt2img-custom-scroll !h-[calc(100%-40px)]'
              data-selected={selectedTab === 'PROMPT_SETTINGS'}
            >
              <RedesignV4 />
            </div>
          ) : (
            <div
              className={`rounded-[10px] md:min-w-[321px] md:max-w-[321px] md:overflow-y-auto overflow-x-visible md:h-full custom-scroll hidden data-[selected=true]:block md:block`}
              data-selected={selectedTab === 'PROMPT_SETTINGS'}
            >
              <Redesign />
            </div>
          )}
          {version >= 'v4.0' ? (
            <div className='relative grow flex flex-col items-center justify-end md:justify-center gap-[15px] 3xl:gap-[18px] px-4'>
              <div className={`relative items-start h-full w-full flex flex-row justify-center max-h-[calc(100%-300px)] ${ version === 'v5.0' ? 'lg:max-h-[calc(100%-190px)]' : 'lg:max-h-[calc(100%-150px)]'}  mb-12 mb:pb-0`}>
                <div
                  className={`flex items-center h-full ${
                    isViewChange
                      ? 'pb-[20px]'
                      : 'pb-[12px] 3xl:pb-[60px]'
                  } justify-center`}
                >
                  {isViewChange ? (
                    <GenerationsV4Grid ref={generationRef} />
                  ) : (
                    <GenerationsV4 ref={generationRef} />
                  )}
                </div>
              </div>

              <PromptInput
                generationRef={generationRef}
                onViewChange={onViewChange}
								fixedPosition={false}
              />
            </div>
          ) : (
            <>
              <div className='relative grow'>
                <div
                  className='h-full hidden data-[selected=true]:block md:block'
                  data-selected={selectedTab === 'TEXT_PROMPT'}
                >
                  <QueueStatus />
                  <Generations ref={generationRef} />
                </div>

                <PromptInput generationRef={generationRef} />
              </div>
            </>
          )}
          {version === 'v4.0' && (
            <div className='grow max-w-[94px] 4xl:h-[calc(100%-180px)] h-[calc(100%-180px)] -mr-4'>
              <V4GenerationHistory />
            </div>
          )}
        </div>

        <ImageViewer />

        <ImageEditor />
      </ProtectedRoute>
    </AppLayout>
  );
};

export default TextPipeline;
