import axios from 'axios';
import queryString from 'query-string';
import environment from './helpers/envProvider';
import authAxiosInstance from './helpers/auth-axios';

// const apiUrl = environment('REACT_APP_AUTH_URL') + '/api' || 'https://auth.logodiffusion.com/api' || 'https://auth-api-lodi.herokuapp.com/api';
// const apiUrl = 'http://localhost:5000/api';

const dataProvider = {
  async getList(resource, params) {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
			admin: true,
    };

    const url = `/api/${resource}?${queryString.stringify(query)}`;

    const res = await authAxiosInstance.get(url);

    const formattedRes = Array.isArray(res.data) ? {
      data: res.data.map(doc => {
        doc.id = doc._id;
        delete doc._id;
        return doc;
      }),
      total: res.data.length
		} : {
      data: res.data.docs.map(doc => {
        doc.id = doc._id;
        delete doc._id;
        return doc;
      }),
      total: res.data.totalDocs
    };

    return formattedRes;
  },

  async getOne(resource, params) {
    const url = `/api/${resource}/${params.id}`;

    const res = await authAxiosInstance.get(url);

    const formattedRes = {
      id: res.data._id,
      ...res.data
    };

    delete formattedRes._id;

    return {
      data: formattedRes,
      total: 1
    };
  },

  async getMany(resource, params) {
    const url = `/api/${resource}/show-many`;

    const res = await authAxiosInstance.post(url, { ids: params.ids });

    return {
      data: res.data.map(i => {
        i.id = i._id;
        delete i._id;
        return i;
      }),
      total: res.data.length
    };
  },

  async create(resource, params) {
    const url = `/api/${resource}`;

    let res;
		console.log(resource)
		if (resource === 'style-transfer-gallery') {
			const formData = new FormData();
			Object.entries(params.data).forEach(([key, val]) => {
				if (val?.rawFile) {
					formData.append(key, val.rawFile)
				} else if(val !== undefined && val !== null) {
					formData.append(key, val)
				}
			})
			res = await authAxiosInstance.post(url, formData);
		} else {
			res = await authAxiosInstance.post(url, params.data);
		}

    const formattedRes = {
      ...res.data,
      id: res.data._id
    };

    delete formattedRes._id;

    return {
      data: formattedRes,
      total: 1
    }
  },

  async update(resource, params) {
    const url = `/api/${resource}/${params.id}`;

    const res = await authAxiosInstance.put(url, { ...params.data });

    const formattedResponse = { ...res.data };

    formattedResponse.id = res.data._id;
    delete formattedResponse._id;

    return {
      data: formattedResponse,
      total: 1
    };
  },

  async getManyReference(resource, params) {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `/api/${resource}?${queryString.stringify(query)}`;

    const { data } = await authAxiosInstance.get(url);
		const { docs, totalDocs } = data;

		if (docs && totalDocs !== undefined) {
			const formattedData = docs.map(d => {
				d.id = d._id;
				delete d._id;
				return d;
			});

			return {
				data: formattedData,
				total: totalDocs
			};
		} else if (Array.isArray(data)) {
			return {
				data: data.map(x => {
					x.id = x._id;
					delete x._id;
					return x
				}),
				total: data.length
			};

		}
  },

  async delete(resource, params) {
    const url = `/api/${resource}/${params.id}`;

    const res = await authAxiosInstance.delete(url);

    const formattedRes = { ...res.data };

    formattedRes.id = res.data._id;
    delete formattedRes._id;

    return {
      data: formattedRes,
      total: 1
    }
  },

  async deleteMany(resource, params) {
    let formattedRes;
    for (let id of params.ids) {
      const url = `/api/${resource}/${id}`;

      const res = await authAxiosInstance.delete(url);

      formattedRes = { ...res.data };

      formattedRes.id = res.data._id;
      delete formattedRes._id;
    }

    return {
      data: formattedRes,
      total: params.ids.length
    }
  }
}

export default dataProvider;
